import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import BgImage from "../../images/students.jpg"
import AppStore from "../../images/appStore.png"
import GooglePlay from "../../images/GooglePlay.jpg"
import Layout from "../../components/layoutPhoenixCustom"
import PageHeaders from "../../components/pageHeaders"
import SEO from "../../components/seo"

const BadgeImage = styled.img`
  width: 160px;
  margin-top: 2rem;
  margin-right: 1rem;
`

const ParagraphText = styled.p`
  font-size: 1.25rem;
`

const HeaderText = styled.h1`
  margin-top: 2rem;
  font-size: 1.5rem;
`

const IndexPage = () => (
  <Layout
    ImgSrc={BgImage}
    MainText="Students @ Cross Church"
    SecondText="Connecting the next generation to Jesus."
  >
    <SEO title="Students @ Cross Church" />
    <PageHeaders
      css={css`
        display: flex;
      `}
    >
      Students @ Cross Church
    </PageHeaders>
    <HeaderText>Our Mission</HeaderText>
    <ParagraphText>
      The mission of Students at Cross Church is to connect the next generation
      to Jesus. We will do this by plugging students into the Gospel message,
      investing biblical truths into their lives, and encouraging them to get
      others plugged in as well.
    </ParagraphText>
    <HeaderText>Who We Are</HeaderText>
    <ParagraphText>
      Cross Church cares about equipping Middle and High School students to Make
      Jesus Known! Every student service, event, and camp has the ultimate aim
      of connecting students to other students, leaders, and ultimately Jesus!
      Sunday services are a time of scripture centered messages, rewhip, and
      small group discussions helping students grow spiritually and in community
      with each other.
    </ParagraphText>
    <PageHeaders
      css={css`
        display: flex;
        margin-top: 6rem;
      `}
    >
      Sunday Mornings
    </PageHeaders>
    <HeaderText>Sundays</HeaderText>
    <ParagraphText>
      Students at Cross Church meet at 10:30am every Sunday morning.
    </ParagraphText>
    <HeaderText>Parent Cue App</HeaderText>
    <ParagraphText>
      Our goal for Students @ Cross Church is to be able to partner with parents
      to help in all stages of life. One way to do that is to download the
      Parent Cue App that follows our curriculum and gives helpful tips at all
      stages of kids' life.
    </ParagraphText>
    <div
      css={css`
        display: flex;
      `}
    >
      <a href="https://itunes.apple.com/us/app/parent-cue/id433066482?mt=8">
        <BadgeImage src={AppStore} alt="Apple Store Icon" />
      </a>
      <a href="https://play.google.com/store/apps/details?id=org.rethinkgroup.parentcuepaid&hl=en">
        <BadgeImage src={GooglePlay} alt="Google Play icon" />
      </a>
    </div>
  </Layout>
)

export default IndexPage
